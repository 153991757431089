<template>
  <div class="py-1">
    <div class="d-flex footer-content" id="selfPlacedPagination">
      <!-- Previous -->
      <div
        class="footer-icon"
        :class="{
          disabled: !showHeaderNav || (isFirstSequence && isFirstUnit)
        }"
        @click="previousUnit"
      >
        <img
          src="https://files.lxp.academy.who.int/learning-experience-platform/vle/nav/arrow-left.svg"
          :disabled="isFirstSequence && isFirstUnit"
          width="24"
          height="24"
        />
      </div>
      <!-- Next -->
      <div class="footer-icon" @click="nextUnit($route.params.id)">
        <img
          src="https://files.lxp.academy.who.int/learning-experience-platform/vle/nav/arrow-right.svg"
          width="24"
          height="24"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import nextUnit from "@/components/SelfPaced/mixins/nextUnit.js";
import previousUnit from "@/components/SelfPaced/mixins/previousUnit.js";
import TIMED_ASSESSMENT_MODAL_IDS from "./TimedAssessment/constants";
export default {
  mixins: [nextUnit, previousUnit],
  data() {
    return {
      TIMED_ASSESSMENT_MODAL_IDS: {}
    };
  },
  created() {
    this.TIMED_ASSESSMENT_MODAL_IDS = TIMED_ASSESSMENT_MODAL_IDS;
  },
  computed: {
    ...mapGetters([
      "isFirstSequence",
      "isFirstUnit",
      "showHeaderNav",
      "getCourseUnitIndex",
      "getAllUnitIds"
    ])
  }
};
</script>
<style lang="scss" scoped>
.footer-content {
  @include horizontal-space-between;
  padding: 4px 8px;
  gap: 16px;
  border-radius: 100px;
  height: 40px;
  background: $brand-primary-75;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.footer-icon {
  cursor: pointer;
  padding: 8px;
  &.disabled {
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      border: none;
    }
    img {
      filter: invert(71%) sepia(3%) saturate(16%) hue-rotate(333deg)
        brightness(90%) contrast(90%) !important;
      opacity: 1;
    }
  }
  &:hover,
  &:active {
    background: $brand-primary-100;
    border-radius: 100px;
  }
  &:active {
    border: 2px solid $brand-primary-400;
    img {
      filter: invert(35%) sepia(54%) saturate(5132%) hue-rotate(198deg)
        brightness(106%) contrast(102%);
    }
  }
  &:focus {
    background: $brand-neutral-0;
    border-radius: 100px;
  }
}
.top-icon-style {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.top-icon-style {
  padding-left: 4px;
  color: $brand-primary-700;
}
</style>
