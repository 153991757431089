<template>
  <div class="forums__list--container" id="forumsListContainer">
    <div
      class="forums__search--container"
      :class="{
        'hide-container': getDiscussionPosts.results.length === 0,
        'show-container': searchPost || unreadFilter || dropdownValue2?.label
      }"
    >
      <div
        id="search-bar"
        class="search-top"
        :class="{ 'search-active': getIsSearchActive }"
      >
        <div class="search-back" @click="resetSearch" v-if="getIsSearchActive">
          <img :src="backIcon" alt="back" />
        </div>
        <div class="forums-categories" v-else>
          <span class="main-category">
            {{ getTopicName.categoryName }}
          </span>
          <span class="sub-category" v-if="getTopicName.subCategoryName">
            {{ getTopicName.subCategoryName }}
          </span>
        </div>
        <div class="forums__search--bar">
          <img
            :src="require('@/assets/images/vle/forums/features/search.svg')"
            alt="search"
          />
          <input
            type="text"
            :maxlength="60"
            class="form-control"
            v-model="searchPost"
            aria-label="Search"
            :placeholder="$t('general.search')"
            @input="handleChange()"
            @focus="setSearchActive"
          />
        </div>
      </div>
      <div class="search-bottom">
        <div class="result-count">
          {{ getDiscussionPosts.count }}
          {{ $t("discussions.post.results_available") }}
        </div>
        <div class="post-filter">
          <div
            :class="[
              'select-category select-filter-item',
              {
                'select__category--active': isDropdownOpen,
                'category-selected': unreadFilter || flaggedFilter
              }
            ]"
            id="select-filter"
            :aria-label="$t('discussions.post.filter_by')"
            @click="toggleDropdown"
          >
            <div
              class="selector"
              @mouseover="isDropdownHovered = true"
              @mouseout="isDropdownHovered = false"
            >
              <span class="label">
                <img
                  :src="
                    isDropdownHovered || isDropdownOpen
                      ? filterHovered
                      : filterDefault
                  "
                  alt="filter"
                  class="image"
                  v-if="!unreadFilter && !flaggedFilter"
                />
                {{ $t("discussions.post.filter_by") }}
                <span v-if="unreadFilter || flaggedFilter" class="filter-count">
                  {{ unreadFilter && flaggedFilter ? "2" : "1" }}
                </span>
              </span>
              <img
                :src="removeIcon"
                alt="remove"
                class="remove"
                v-if="unreadFilter || flaggedFilter"
                @click="removeValue('filter')"
              />
              <img
                :src="
                  isDropdownHovered || isDropdownOpen
                    ? arrowHovered
                    : arrowDefault
                "
                alt="arrow"
                :style="{
                  transform: isDropdownOpen ? 'rotate(180deg)' : 'none'
                }"
                v-else
              />
            </div>
            <div
              class="select-item filter__select--item"
              :class="{ 'hide-dropdown': !isDropdownOpen }"
            >
              <ul class="select__item--inner">
                <li class="filter-item" :class="{ active: unreadFilter }">
                  <label for="unreadFilter">
                    {{ $t("discussions.post.unread") }}
                    <input
                      id="unreadFilter"
                      type="checkbox"
                      name="unreadFilter"
                      v-model="unreadFilter"
                      class="filter-checkbox"
                    />
                    <img
                      :src="checkboxChecked"
                      alt="checked"
                      class="checkmark"
                    />
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="select-category select__sort--item"
            :class="{
              'select__category--active': isDropdownOpen2,
              'category-selected': dropdownValue2?.label
            }"
            id="select-category"
            :aria-label="$t('discussions.post.sort_by')"
            @click="toggleDropdown2"
          >
            <div
              class="selector"
              :class="{ active: dropdownValue2?.label }"
              @mouseover="isDropdownHovered2 = true"
              @mouseout="isDropdownHovered2 = false"
            >
              <span class="label">
                <img
                  :src="
                    isDropdownHovered2 || isDropdownOpen2
                      ? sortHovered
                      : sortDefault
                  "
                  alt="sorting"
                  class="image"
                  v-if="!dropdownValue2?.label"
                />
                {{ $t("discussions.post.sort_by") }}
                <span v-if="dropdownValue2?.label">{{
                  dropdownValue2?.label
                }}</span>
              </span>
              <img
                :src="removeIcon"
                alt="remove"
                class="remove"
                v-if="dropdownValue2?.label"
                @click="removeValue('sort')"
              />
              <img
                :src="
                  isDropdownHovered2 || isDropdownOpen2
                    ? arrowHovered
                    : arrowDefault
                "
                alt="arrow"
                :style="{
                  transform: isDropdownOpen2 ? 'rotate(180deg)' : 'none'
                }"
                v-else
              />
            </div>
            <div class="select-item" v-if="isDropdownOpen2">
              <ul class="select__item--inner">
                <li
                  v-for="(item, index) in sortItems"
                  :key="index"
                  @click="selectDropdownItem2(item)"
                  class="clickable-category"
                  :class="{ current: item.value === dropdownValue2?.value }"
                >
                  <span>{{ item.label }}</span>
                  <img
                    :src="checkIcon"
                    alt="tick"
                    v-if="item.value === dropdownValue2?.value"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="shimmer-container"
      v-if="
        getDraftListIsLoading &&
          !getTopicId &&
          !searchPost &&
          !unreadFilter &&
          !dropdownValue2?.label
      "
    >
      <shimmer variant="discussion-forums-post" />
    </div>
    <template v-else>
      <VueSlickCarousel
        v-bind="draftPostListOptions"
        class="slick__slider--container draft__post--slider"
        v-if="
          getDiscussionDraftPosts.results.length > 0 &&
            !getTopicId &&
            !searchPost &&
            !unreadFilter &&
            !dropdownValue2?.label
        "
      >
        <div
          class="spadding-container"
          v-for="(c, index) in getDiscussionDraftPosts.results"
          :key="index"
        >
          <DiscussionCard :cardData="c" />
        </div>
      </VueSlickCarousel>
    </template>
    <div class="shimmer-container" v-if="!isLazyLoading && getListIsLoading">
      <div class="forums__list--shimmer" v-for="c in 2" :key="c">
        <shimmer variant="discussion-forums-post" />
      </div>
    </div>
    <div
      v-else
      class="post__list--container"
      :class="{
        'empty__search--container': getDiscussionPosts.results.length === 0
      }"
    >
      <template v-if="getDiscussionPosts.results.length > 0">
        <DiscussionCard
          v-for="(c, index) in getDiscussionPosts.results"
          :cardData="c"
          :key="index"
        />
      </template>
      <template v-else>
        <div
          class="search__empty--post"
          v-if="searchPost || unreadFilter || dropdownValue2?.label"
        >
          <div class="ep-image">
            <img :src="noSearchIcon" alt="no search" />
          </div>
          <div class="ep-title">
            {{ $t("discussions.post_list.empty.title") }}
          </div>
          <div class="ep-content">
            {{ $t("discussions.post_list.empty.content") }}
          </div>
        </div>
        <div
          class="empty-post"
          :class="{
            'hide-empty-post':
              !getTopicId && getDiscussionDraftPosts.results.length > 0
          }"
          v-else
        >
          <div class="ep-image">
            <img :src="emptyPostIcon" alt="no search" />
          </div>
          <div class="ep-title">
            {{ $t("discussions.post_list.empty_post.title") }}
          </div>
          <div class="ep-content">
            {{ $t("discussions.post_list.empty_post.content") }}
          </div>
          <div class="ep__info--container">
            <div class="info-item">
              <div class="item-title">
                <img :src="editIcon" alt="info" />
                <span>{{
                  $t("discussions.post_list.empty_post.info1.title")
                }}</span>
              </div>
              <div class="item-content">
                {{ $t("discussions.post_list.empty_post.info1.content") }}
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">
                <img :src="textIcon" alt="info" />
                <span>{{
                  $t("discussions.post_list.empty_post.info2.title")
                }}</span>
              </div>
              <div class="item-content">
                {{ $t("discussions.post_list.empty_post.info2.content") }}
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">
                <img :src="incognitoIcon" alt="info" />
                <span>{{
                  $t("discussions.post_list.empty_post.info3.title")
                }}</span>
              </div>
              <div class="item-content">
                {{ $t("discussions.post_list.empty_post.info3.content") }}
              </div>
            </div>
          </div>
          <div class="ep-btn" @click="showAddPostForm" role="button">
            {{ $t("discussions.post.add") }}
          </div>
        </div>
      </template>
    </div>
    <div v-if="isLazyLoading">
      <LxpLoader />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DiscussionCard from "./DiscussionCard.vue";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
import { LxpLoader } from "didactica";
export default {
  mixins: [discussionViewSelectorMixin],
  components: { DiscussionCard, LxpLoader },
  computed: {
    ...mapGetters([
      "getDiscussionPosts",
      "getDiscussionDraftPosts",
      "getTopicId",
      "getDiscussionViewSelector",
      "getTopicName",
      "getListIsLoading",
      "getDraftListIsLoading",
      "isVleSideNavOpen",
      "getIsSearchActive"
    ])
  },
  props: {
    selectedCategoryName: Object
  },
  data() {
    return {
      dropdownValue: this.$t("discussions.post.filter_by"),
      dropdownValue2: null,
      isDropdownOpen: false,
      isDropdownOpen2: false,
      isDropdownHovered: false,
      isDropdownHovered2: false,
      arrowDefault: require("@/assets/images/vle/chevron-blue.svg"),
      arrowHovered: require("@/assets/images/vle/chevron-lightblue.svg"),
      filterDefault: require("@/assets/images/vle/forums/filter-black.svg"),
      filterHovered: require("@/assets/images/vle/forums/filter-lightblue.svg"),
      sortDefault: require("@/assets/images/vle/forums/sorting-black.svg"),
      sortHovered: require("@/assets/images/vle/forums/sorting-lightblue.svg"),
      checkIcon: require("@/assets/images/vle/checkmark.svg"),
      removeIcon: require("@/assets/images/vle/close-icon.svg"),
      backIcon: require("@/assets/images/vle/arrow-left.svg"),
      noSearchIcon: require("@/assets/images/vle/forums/no-search.svg"),
      emptyPostIcon: require("@/assets/images/vle/empty-chat.svg"),
      editIcon: require("@/assets/images/vle/edit.svg"),
      textIcon: require("@/assets/images/vle/text-icon.svg"),
      incognitoIcon: require("@/assets/images/vle/incognito-icon.svg"),
      checkboxChecked: require("@/assets/images/vle/checkbox-checked.svg"),
      sortItems: [
        {
          value: "votes",
          label: this.$t("discussions.post.most_likes")
        },
        {
          value: "activity",
          label: this.$t("discussions.post.recent_activity")
        },
        {
          value: "comments",
          label: this.$t("discussions.post.most_activity")
        }
      ],
      searchPost: "",
      isLazyLoading: false,
      timeout: null,
      unreadFilter: false,
      flaggedFilter: false,
      showShimmer: false,
      draftPostListOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        arrows: true,
        infinite: true,
        autoplay: false
      }
    };
  },
  mounted() {
    document.addEventListener("click", this.searchOutsideClickHandler);
    this.setupComponent();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.searchOutsideClickHandler);
    const scrollContainer = document.getElementById("ul-content__main");
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.amountScrolled);
    }
  },
  methods: {
    setupComponent() {
      const scrollContainer = document.getElementById("ul-content__main");
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", this.amountScrolled);
      }

      //loads normal posts
      const obj = { ...this.constructParams(), page: 1 };
      this.loadPost(obj);

      //load draft posts
      const draftParams = { ...this.constructDraftParams() };
      this.loadDraftPosts(draftParams);
    },
    amountScrolled() {
      let postListWrapper = document.getElementById("ul-content__main"),
        documentHeight =
          postListWrapper.scrollHeight - postListWrapper.clientHeight,
        scrollTopPosition = postListWrapper.scrollTop,
        scrolledPercentage = Math.floor(
          (scrollTopPosition / documentHeight) * 100
        );
      if (
        scrolledPercentage >= 90 &&
        !this.isLazyLoading &&
        this.getDiscussionPosts.next_page !== null
      ) {
        this.isLazyLoading = true;
        let obj = this.constructParams();
        this.loadPost(obj);
      }
    },
    constructParams() {
      let obj = {
        course_id: this.$route.params.id,
        topic_id: this.getTopicId,
        page: this.getDiscussionPosts.next_page,
        text: this.searchPost,
        sort_key: this.dropdownValue2?.value,
        flagged: this.flaggedFilter,
        unread: this.unreadFilter
      };
      return obj;
    },
    constructDraftParams() {
      let obj = {
        course_id: this.$route.params.id,
        topic_id: this.getTopicId
      };
      return obj;
    },
    showAddPostForm() {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.AddPostForm
      );
      if (this.$route.params.topic_id) {
        this.$router
          .push({
            name: "TopicPost",
            params: { topic_id: this.$route.params.topic_id }
          })
          .catch(err => err);
      } else {
        this.$router
          .push({
            name: "AddPost"
          })
          .catch(err => err);
      }
    },
    selectDropdownItem(item) {
      this.dropdownValue = item.name;
      this.isDropdownOpen = false;
    },
    selectDropdownItem2(item) {
      this.dropdownValue2 = item;
      this.isDropdownOpen2 = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        document.addEventListener("click", this.outsideClickHandler);
      } else {
        document.removeEventListener("click", this.outsideClickHandler);
      }
    },
    toggleDropdown2() {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
      if (this.isDropdownOpen2) {
        document.addEventListener("click", this.outsideClickHandler2);
      } else {
        document.removeEventListener("click", this.outsideClickHandler2);
      }
    },
    outsideClickHandler(event) {
      const dropdown = document.getElementById("select-filter");
      if (!dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
        document.removeEventListener("click", this.outsideClickHandler);
      }
    },
    outsideClickHandler2(event) {
      const dropdown = document.getElementById("select-category");
      if (!dropdown.contains(event.target)) {
        this.isDropdownOpen2 = false;
        document.removeEventListener("click", this.outsideClickHandler2);
      }
    },
    searchOutsideClickHandler(event) {
      const searchBar = document.getElementById("search-bar");
      if (searchBar && !searchBar.contains(event.target)) {
        this.$store.commit("SET_IS_SEARCH_ACTIVE", false);
      }
    },
    handleChange() {
      let obj = { ...this.constructParams(), page: 1 };
      this.$store.commit("SET_LIST_POST_LOADING", true);
      this.debounce(() => {
        this.loadPost(obj);
      });
    },
    debounce(func, wait = 500) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    loadPost(obj) {
      if (obj.page === 1) {
        this.resetListState();
      }
      let dispatchAction = "";
      if (this.getTopicId) {
        dispatchAction = "getListOfPosts";
      } else {
        dispatchAction = "getListOfAllPosts";
      }
      this.$store
        .dispatch(dispatchAction, obj)
        .then(() => {
          this.isLazyLoading = false;
        })
        .catch(() => {
          this.isLazyLoading = false;
        });
    },
    loadDraftPosts(draftParams) {
      let dispatchAction = "";
      if (this.getTopicId) {
        dispatchAction = "getDraftListOfPosts";
      } else {
        dispatchAction = "getListOfAllDraftPosts";
      }
      this.$store.dispatch(dispatchAction, draftParams).then();
    },
    removeValue(dropdown) {
      if (dropdown === "sort") {
        this.dropdownValue2 = null;
      } else {
        this.unreadFilter = false;
        this.flaggedFilter = false;
      }
    },
    setSearchActive() {
      this.$store.commit("SET_IS_SEARCH_ACTIVE", true);
    },
    resetSearch() {
      this.$store.commit("SET_IS_SEARCH_ACTIVE", false);
      if (this.searchPost) {
        this.searchPost = "";
        let obj = { ...this.constructParams(), page: 1, text: "" };
        this.loadPost(obj);
      }
    },
    resetListState() {
      this.$store.commit("RESET_LIST_POST");
    }
  },
  watch: {
    getTopicId(oldvalue, newValue) {
      if (oldvalue !== newValue) {
        this.searchPost = "";
      }
      let obj = { ...this.constructParams(), page: 1 };
      this.loadPost(obj);
      const draftParams = { ...this.constructDraftParams() };
      this.loadDraftPosts(draftParams);
    },
    dropdownValue2() {
      let obj = { ...this.constructParams(), page: 1 };
      this.loadPost(obj);
    },
    flaggedFilter() {
      let obj = { ...this.constructParams(), page: 1 };
      this.loadPost(obj);
    },
    unreadFilter() {
      let obj = { ...this.constructParams(), page: 1 };
      this.loadPost(obj);
    }
  }
};
</script>
<style lang="scss" scoped>
.select-category {
  position: relative;
  z-index: 1;

  &:not(:first-child) {
    margin-left: 10px;
  }

  .selector {
    padding: 6px 8px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    .label {
      @include label-large($brand-neutral-700, 500);
      white-space: nowrap;
      display: flex;
      align-items: center;
      .image {
        margin-right: 8px;
      }
      span {
        margin-left: 8px;
        color: $brand-primary-400;
        text-transform: capitalize;
        &.filter-count {
          padding: 2px 4px;
          background-color: $brand-primary-400;
          display: inline-block;
          border-radius: 4px;
          color: $brand-neutral-0;
          @include label-medium;
        }
      }
    }

    > img {
      width: 18px;
      margin-left: 8px;
      &.remove {
        width: 16px;
      }
    }

    &:hover {
      .label {
        color: $brand-primary-400;
      }
    }
  }

  .select-item {
    width: 130px;
    padding: 0px;
    border-radius: 8px;
    background-color: $brand-neutral-0;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 1;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    overflow: hidden;

    &.filter__select--item {
      &.hide-dropdown {
        display: none;
      }
    }

    .select__item--inner {
      padding: 0px;
      margin: 0;
      list-style-type: none;
    }

    .clickable-category {
      padding: 8px;
      @include body-medium;
      color: $brand-neutral-700;
      cursor: pointer;
      @include horizontal-space-between;

      &:hover,
      &.current {
        background-color: $brand-primary-100;
        color: $brand-neutral-900;
      }
      img {
        margin-left: 8px;
        width: 12px;
      }
    }

    .filter-item {
      label {
        padding: 8px;
        cursor: pointer;
        @include horizontal-space-between;
        @include body-medium;
        color: $brand-neutral-700;
        margin: 0;
      }
      .checkmark {
        display: none;
      }
      .filter-checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 11px;
        height: 11px;
        border: 1px solid $brand-neutral-700;
        border-radius: 3px;
        cursor: pointer;
      }

      &:hover,
      &.current {
        background-color: $brand-primary-100;
        .filter-checkbox:not(:checked) {
          box-shadow: 0 0 0 0.2rem $brand-primary-200;
          border-color: $brand-primary-400;
        }
      }
      &.active {
        label {
          color: $brand-neutral-900;
          background-color: $brand-primary-100;
        }
        .checkmark {
          display: block;
          position: absolute;
          right: 8px;
        }
      }
    }
  }
  &.select__category--active {
    .selector {
      background-color: $brand-primary-100;
      .label {
        color: $brand-primary-400;
      }
    }
  }
  &.category-selected {
    .selector {
      background-color: $brand-primary-100;
      .label {
        color: $brand-primary;
      }
    }
  }
}
.forums__list--container {
  margin-bottom: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.forums__search--container {
  &.hide-container:not(.show-container) {
    display: none;
  }
  .search-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &.search-active {
      align-items: center;
      .forums__search--bar {
        flex: 1;
      }
    }

    .forums-categories {
      display: flex;
      flex-direction: column;
      padding-right: 8px;

      .main-category {
        @include body-regular($brand-neutral-700, 500);
        letter-spacing: 0.15px;
        word-break: break-word;
      }
      .sub-category {
        @include label-large($brand-neutral-300, 500);
        word-break: break-word;
      }
    }

    .forums__search--bar {
      position: relative;
      width: 230px;
      min-width: 230px;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
      }
      .form-control {
        width: 100%;
        padding: 4px 16px 4px 48px;
        border: 1px solid $brand-neutral-200;
        border-radius: 100px;
        color: $brand-neutral-300;
        background-color: $brand-neutral-0;
        @include body-medium;

        &:focus {
          box-shadow: none;
          border-color: $brand-primary;
          color: $brand-primary;
          background-color: $brand-primary-75;
        }
      }
    }
    .search-back {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      @include flex-horizontal-center;
      background-color: $brand-neutral-50;
      cursor: pointer;
      margin-right: 32px;
    }
  }

  .search-bottom {
    margin-top: 16px;
    @include horizontal-space-between;

    .result-count {
      @include label-large($brand-neutral-300, 400);
    }

    .post-filter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.post__list--container {
  padding: 24px 0px;
}
.empty__search--container {
  flex: 1;
  display: flex;
  align-items: center;
}
.search__empty--post {
  width: 100%;
  background-color: $brand-neutral-0;
  border-radius: 8px;
  padding: 24px;
  @include flex-column-center;
  .ep-title {
    @include subtitle-regular;
    color: $brand-neutral-700;
    margin-top: 24px;
  }
  .ep-content {
    @include body-regular($brand-neutral-300);
    margin-top: 8px;
  }
}
.empty-post {
  width: 100%;
  background-color: $brand-neutral-0;
  border-radius: 8px;
  padding: 16px 24px 16px;
  @include flex-column-center;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  &.hide-empty-post {
    display: none;
  }
  .ep-title {
    @include subtitle-large($brand-neutral-900);
    margin-top: 24px;
  }
  .ep-content {
    @include body-regular($brand-neutral-700);
    margin-top: 8px;
  }
  .ep__info--container {
    margin-top: 24px;
    border-radius: 8px;
    background-color: $forums-secondary-bg;
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .info-item {
      flex: 1;
      @include flex-column-center;
      text-align: center;
      max-width: 170px;
      .item-title {
        display: flex;
        align-items: center;
        @include body-regular($brand-neutral-900, 500);
        span {
          margin-left: 4px;
        }
      }
      .item-content {
        @include body-medium;
        margin-top: 4px;
        color: $brand-neutral-700;
      }
    }
  }
  .ep-btn {
    border-radius: 100px;
    border: 1px solid $brand-primary;
    padding: 8px 58px;
    width: 193px;
    display: flex;
    justify-content: center;
    color: $brand-primary;
    background-color: $brand-neutral-0;
    @include button-label;
    margin-top: 24px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: $brand-neutral-0;
      background-color: $brand-primary;
    }
  }
}
.shimmer-container {
  width: 100%;
  padding: 24px 0px;
  .forums__list--shimmer {
    width: 100%;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
}
.draft__post--slider {
  margin-top: 24px;
}
[dir="rtl"] {
  .select-category {
    &:not(:first-child) {
      margin-left: 0px;
      margin-right: 10px;
    }
    .selector {
      .label {
        .image {
          margin-right: 0px;
          margin-left: 8px;
        }
        span {
          margin-left: 0px;
          margin-right: 8px;
        }
      }
      > img {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
    .select-item {
      .filter-item.active {
        .checkmark {
          right: auto;
          left: 8px;
        }
      }
    }
  }
}
</style>
