<template>
  <div
    :class="[
      'sp-main__wrapper',
      {
        'sp-main__wrapper--b0':
          getCourseViewSelector === 1 ||
          (getCourseViewSelector === 3 && getCurrentMenuItem === 'modules'),
        'sp-main__wrapper--scorm':
          isScormUnit && getCurrentMenuItem === 'modules'
      }
    ]"
  >
    <div
      class="sp-main__container"
      :class="{ 'forums-main__container': getCurrentMenuItem === 'forums' }"
    >
      <div class="sp-main__loader" v-if="isLoading">
        <Shimmer />
      </div>
      <template v-else>
        <div v-if="getCurrentMenuItem === 'modules'" class="h-100">
          <SelfPacedWrapper @onFinish="$emit('onFinish')" />
        </div>
        <div v-else-if="getCurrentMenuItem === 'teams'" class="h-100">
          <TeamsWrapper />
        </div>
      </template>
    </div>
    <div class="p-0" v-if="showFooter">
      <Footer
        @onFinish="handleOnFinish"
        @hasCredentials="hasCredentials"
        v-if="!isLoading"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import courseCompletion from "@/components/SelfPaced/mixins/courseCompletion.js";

export default {
  mixins: [courseCompletion],
  components: {
    SelfPacedWrapper: () => import("./SelfPacedWrapper.vue"),
    TeamsWrapper: () => import("@/components/Teams/TeamsWrapper.vue"),
    Footer: () => import("@/components/SelfPaced/Footer.vue"),
    Shimmer: () => import("@/components/WrapperComponents/Shimmer.vue")
  },
  data() {
    return {
      isLoading: true
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    isMainViewLoading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentSequenceId",
      "getCurrentUnitIndex",
      "getCurrentUnitId",
      "getSessionCookie",
      "isSessionCookieExists",
      "getCurrentMenuItem",
      "iframeContentStatus",
      "getCourseViewSelector",
      "getModuleToCheckMC",
      "isScormUnit"
    ]),
    showFooter() {
      return (
        this.getCurrentMenuItem === "modules" &&
        this.getCourseViewSelector !== 1 &&
        this.getCourseViewSelector !== 3 &&
        this.iframeContentStatus &&
        !this.isMainViewLoading &&
        !this.isScormUnit
      );
    }
  },
  watch: {
    getCurrentSequenceId(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.setup();
      }
    },
    getSessionCookie(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.setup();
      }
    },
    getCurrentMenuItem(newValue) {
      this.checkIfTeamsLoaded(newValue);
    }
  },
  created() {
    if (this.isSelfPacedLayoutLoaded() === true) {
      this.checkIfTeamsLoaded(this.getCurrentMenuItem);
    }
  },
  methods: {
    setup() {
      // TODO:: Add a watch to `isLoading` and emit the `mainViewLoading` to avoid
      // code repetation and a chance of missing it during the new implementation.
      this.isLoading = true;
      this.$emit("mainViewLoading", this.isLoading);
      if (this.isSessionCookieExists) {
        if (this.getCurrentSequenceId && this.getCurrentSequenceId !== "") {
          // When we move from one sub-section to another, the watch method in this
          // component triggered by the course API response change(TODO:: instead of
          // calling the courses API, we need to call the unit completion API to
          // retrieve the completion status), before the currentSequece gets updated
          // in the store so the `getCurrentUnitIndex` return `-1` and in the API
          // request it was sent as `0`, but the API accepts  only the values from `1`.
          //
          // So here we're manually resetting the position value to 1
          const position =
            this.getCurrentUnitIndex && this.getCurrentUnitIndex !== -1
              ? this.getCurrentUnitIndex + 1
              : 1;

          // Make parallel API calls
          this.$store.dispatch("updateUnitPosition", {
            courseId: this.getSelectedCourse.id,
            sequenceId: this.getCurrentSequenceId,
            position: position
          });
          this.$store.commit(
            "SET_CURRENT_UNIT_ID",
            this.getCurrentUnitId || this.getCurrentSequence.units[0].id
          );
          Promise.all([
            this.$store.dispatch("getACourse", {
              course_id: this.$route.params.id,
              version: "v2"
            })
          ]).then(() => {
            this.isLoading = false;
            this.isTimedAssessment();
            this.$emit("mainViewLoading", this.isLoading);
            // while switching to different module, check if the previously
            // visited module has credentials
            this.hasCredentials();
          });
        } else {
          this.isLoading = false;
          this.$emit("mainViewLoading", this.isLoading);
        }
      } else {
        this.$store.dispatch("exchangeSessionCookie");
      }
    },
    isTimedAssessment() {
      // to check if timed assessment is there or not
      if (this.getCurrentSequence.is_timed_assessment === true) {
        const params = {
          courseId: this.$route.params.id,
          sequenceId: this.getCurrentSequenceId
        };
        this.$store.dispatch("getTimedAssessment", params);
      }
      // reset timed assessment value and flag, change layout to course view
      else if (this.getCurrentSequence.is_timed_assessment === false) {
        this.$store.commit("SET_TIMED_ASSESSMENT_FLAG", false);
        this.$store.commit("SET_COURSE_VIEW_SELECTOR", 2);
      }
    },
    hasCredentials() {
      // get the updated object from store and emit
      let selectedCourse = this.getSelectedCourse;
      if (
        selectedCourse &&
        selectedCourse.sections &&
        selectedCourse.sections.length > 0 &&
        this.getModuleToCheckMC &&
        this.getModuleToCheckMC.id
      ) {
        selectedCourse.sections.some(m => {
          if (m.id === this.getModuleToCheckMC.id) {
            this.$emit("hasCredentials", m);
            return true;
          }
        });
      }
    },
    checkIfTeamsLoaded(v) {
      if (v !== "teams") {
        this.setup();
      } else {
        this.isLoading = false;
        this.$emit("mainViewLoading", this.isLoading);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.sp-main__wrapper {
  height: 100%;
  &.sp-main__wrapper--b0 {
    margin-top: 60px;
    .sp-main__container {
      box-shadow: none;
      background: transparent;
    }
  }
  &.sp-main__wrapper--scorm {
    .sp-main__container {
      background: transparent;
      box-shadow: none;
      padding: 0;

      .sp-main__loader {
        background: #fff;
        border-radius: 16px;
        margin: 28px 80px 0;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        padding: 24px 16px;
        min-height: 670px;
      }
    }
  }

  .sp-main__container {
    height: 100%;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    border-radius: 16px;
    padding: 24px 0px;
    background: #ffffff;

    .sp-main__loader {
      padding: 0 16px 10px;
    }
  }
}
</style>
